<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <template>
      <v-data-table :headers="tableHeader" :items="users" sort-by="calories" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Manage Administrators</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogUser" max-width="1000px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                  New Administrator
                </v-btn>
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" @submit.prevent="save" lazy-validation>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="user.name" :rules="nameRules" label="Full Name" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="user.email" :rules="emailRules"  label="Email"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="editedUID == -1">
                        <v-text-field v-model="user.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRules.required, passwordRules.min]" :type="showPassword ? 'text' : 'password'" label="Password" counter @click:append="showPassword = !showPassword"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" type="submit" text>
                    Save
                  </v-btn>
                </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to delete?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteUserConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editUser(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteUser(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </template>
    <v-dialog v-model="dialogLoad" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Wait please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { db, functions } from '../../../plugins/firebase'
import 'firebase/functions'
export default {
  data () {
    return {
      tableHeader: [
        { text: 'FullName', value: 'Name' },
        { text: 'Email', value: 'Email' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      users: [],
      user: {
        name: '',
        email: '',
        password: '',
      },
      nameRules: [
        v => (v && v.length >= 2) || 'Minimum length is 2 characters',
        v => (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 4 || 'Min 4 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      clearUser: {
        name: '',
        email: '',
        password: '',
      },
      editedUID: -1,
      dialogUser: false,
      dialogDelete: false,
      dialogLoad: false,
      valid: true,
      alertError: '',
      alertSuccess: '',
      showPassword: false,
      table: 'SuperAdmin'
    }
  },
  created () {
    this.Users();
    // db.collection("Students").where("Alumno.MisionActual", "==", 7).get()
    // .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //         // doc.data() is never undefined for query doc snapshots
    //         console.log(doc.data());
    //     });
    // })
    // .catch((error) => {
    //     console.log("Error getting documents: ", error);
    // });
    // var agregar =functions.httpsCallable('crearUsuarioUidPersonalizado');
    // agregar().then((user) => {
    //         console.log(user)
    //       })
  },
  methods: {
    editUser (data) {
      this.editedUID = data.id;
      this.user = Object.assign({}, data)
      this.dialogUser = true
    },
    deleteUser (data) {
      this.editedUID = data.id;
      this.user = Object.assign({}, data)
      this.dialogDelete = true
    },
    deleteUserConfirm () {
      this.deleteData(this.editedUID).then((answer) => {
        if (answer.result) {
          this.alertSuccess = "deleted successfully";
        } else {
          this.alertError = answer.message;
        }
      });
      this.closeDelete()
    },
    close () {
      this.dialogUser = false
      this.$refs.form.resetValidation()
      this.$nextTick(() => {
        this.user = Object.assign({}, this.clearUser)
        this.editedUID = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.user = Object.assign({}, this.clearUser)
        this.editedUID = -1
      })
    },
    save () {
      if (!this.$refs.form.validate()) return;
      if (this.editedUID !== -1) {
        this.dialogLoad = true;
        this.updateData(this.user).then(response => {
          console.log(response);
          if (response.result) {
            this.alertSuccess = "updated successfully";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          } else {
            let principal = this;
            if (Object.prototype.hasOwnProperty.call(response.message, 'code')) {
              switch (response.message.code) {
                case 'not-found':
                  this.alertError = 'not found user';
                  break;
                default:
                  this.alertError = response.message.code;
              }
            } else {
              this.alertError = response.message;
            }
            setTimeout(function () { principal.alertError = '' }, 3000);
          }
          this.dialogLoad = false
        });
      } else {
        this.dialogLoad = true;
        this.createData(this.user).then(response => {
          console.log(response);
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () { principal.alertError = '' }, 3000);
          } else {
            this.alertSuccess = "create successfully";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          }
          this.dialogLoad = false
        });
      }
      this.close()
      this.$refs.form.resetValidation()
    },
    validate () {
      this.$refs.form.validate()
    },
    // llamadas a firebase
    async createData (user) {
      let answer = { result: '', message: '' }
      try {
        user.table = this.table;
        const setAdmin = functions.httpsCallable('createUserPrivate')
        await setAdmin(user)
          .then(() => {
            answer.result = true;
          })
      } catch (error) {
        answer.result = false;
        answer.message = error;
        // console.log(error);
      }
      return answer;
    },
    async updateData (user) {
      let answer = { result: '', message: '' }
      try {
        user.table = this.table;
        const updatetAdmin = functions.httpsCallable('updateUserPrivate')
        await updatetAdmin(user)
          .then(() => {
            answer.result = true;
          })
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log('entro al log general');
      }
      return answer;
    },
    async Users () {
      console.log("recibiendo usuarios");
      await db.collection(this.table).onSnapshot((snapshotChange) => {
        this.users = [];
        snapshotChange.forEach((doc) => {
          console.log(doc.data());
          let oneStudent = doc.data();
          oneStudent.id = doc.id;
          this.users.push(oneStudent);
        });
      }, (error) => {
        console.log(error);
      })
    },
    async deleteData (id) {
      let answer = { resutl: '', message: '' }
      let uidLogin = this.$store.state.user.uid;
      if (uidLogin === id) {
        answer.mensaje = 'this user is actually login';
        answer.resultado = false;
        return answer;
      }
      try {
        const deleteAdmin = functions.httpsCallable('deleteUserAdmin')
        await deleteAdmin(id)
          .then(() => {
            answer.result = true;
          })
      } catch (error) {
        answer.message = error;
        answer.resutl = false;
      }
      return answer;
    }
  },
  computed: {
    formTitle () {
      return this.editedUID === -1 ? 'New Administrator' : 'Edit Administrator'
    },
  },
  watch: {
    dialogUser (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogLoad (val) {
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  }
}
</script>
